<template>
  <div class="fill-height">
    <div class="py-2 px-4 elevation-2 page-error-bar" v-if="showError">
      <v-icon color="white">mdi-alert-outline</v-icon>
      <span class="white--text pl-3">{{ error }}</span>
    </div>
    <v-container fluid class="container-main">
      <v-row justify="start">
        <v-col cols="12" class="pb-1">
          <h2>{{ title }}</h2>
          <p class="text-body-1 font-italic text--secondary mb-0">
            Overview for {{ userDisplay }} ({{ userName }})
          </p>
        </v-col>
        <v-col cols="12">
          <v-system-bar color="accent" height="5"></v-system-bar>
          <div class="d-flex">
            <v-text-field
              class="pb-2"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </div>
          <v-data-table
            :headers="reviewsHeader"
            :items="localReviews"
            dense
            :search="search"
            :items-per-page="25"
            @click:row="handleReview"
            class="selectable"
            :footer-props="{ itemsPerPageOptions: [25, 50, 100] }"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import * as disp from "../../utility/display";
import * as dUtil from "../../utility/dateutil";

// @ is an alias to /src
export default {
  name: "UserDetails",
  data: () => ({
    panel: [],
    localReviews: [],
    loading: true,
    error: "",
    search: "",
  }),
  components: {},
  mounted() {
    let pageKey = this.$route.params.key;
    if (this.userId != pageKey) {
      this.userId = pageKey;
    }

    this.loadUserData(this.userId);
    this.loadResumeReviews(this.userId).then(this.loadData);

  },
  methods: {
    ...mapActions("adminUserReviews", ["loadResumeReviews", "loadUserData"]),
    ...mapActions("currentReview", ["loadReviewAdmin"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    async handleReview(value) {
      await this.loadReviewAdmin({ userId: value.userId, reviewId: value.id });
      if (!disp.IsNullorWhitespace(this.loadResumeError)) {
        this.error = this.loadResumeError;
      } else {
        this.nav(`/ResumeReviewWriter/${value.userId}/${value.id}`);
      }
    },
    setError(error) {
      this.error = error;
    },
    loadData() {
      let tempList = [];
      this.reviews.forEach((r) => {
        let nr = {
          dateSubmitted: dUtil.dateStringToFormat(r.dateSubmitted),
          dateSubmittedSort: r.dateSubmitted,
          status: r.status,
          name: r.name,
          note: r.note,
          reviewerDisplay: r.reviewerDisplay,
          dateReviewed: dUtil.dateStringToFormat(r.dateReviewed),
          commentCount: r.comments?.length ?? 0,
          id: r.id,
          userId: this.userId,
        };
        tempList.push(nr);
      });

      tempList.sort((a, b) => {
        try {
          const dtA = new Date(a.dateSubmittedSort);
          const dtB = new Date(b.dateSubmittedSort);
          return dtB.getTime() - dtA.getTime();
        } catch {
          return 0;
        }
      });

      this.localReviews = tempList;
    },
  },
  computed: {
    ...mapFields("adminUserReviews", [
      "reviews",
      "userDisplay",
      "userId",
      "loadUserResumeError",
      "userName",
    ]),
    ...mapFields("currentReview", ["loadResumeError"]),
    title() {
      if (disp.IsNullorWhitespace(this.userDisplay)) {
        return "User Details";
      } else {
        return `User Details: ${this.userDisplay}`;
      }
    },
    showError() {
      return !disp.IsNullorWhitespace(this.error);
    },
    reviewsHeader() {
      return [
        { text: "Date Submitted", sortable: true, value: "dateSubmitted" },
        { text: "Status", sortable: true, value: "status" },
        { text: "Name", sortable: true, value: "name" },
        { text: "Note", sortable: true, value: "note" },
        { text: "Reviewer", sortable: true, value: "reviewerDisplay" },
        { text: "Date Reviewed", sortable: true, value: "dateReviewed" },
        { text: "Comment Count", sortable: true, value: "commentCount" },
      ];
    },
  },
  watch: {},
};
</script>


<style scoped>
.resume-img {
  background-color: var(--v-resumeBackground-base);
  z-index: 1;
}

.button-holder {
  min-width: 140px;
}

.selectable {
  cursor: pointer;
}
</style>